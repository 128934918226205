<template>
  <Support />
</template>

<script>
import Support from "../components/information/Support.vue";

export default {
  components: {
    Support,
  },
  created() {
    const lang = this.$i18n.locale === "tr" ? "" : this.$i18n.locale;
    location.href = `https://flinktax.de/${lang}/support`;
  },
};
</script>

<style></style>
