<template>
  <div class="support-page text-center">
    <h1 class="mb-4">{{ $t("supportHelp") }}</h1>
    <p class="mb-4">
      {{ $t("supportText") }}
      <a href="mailto:support@flinktax.de" class="support-link"
        >support@flinktax.de</a
      >
      {{ $t("supportContactText") }}
    </p>
    <a href="mailto:support@flinktax.de">
      <button class="blue-button">{{ $t("supportContact") }}</button>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.support-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: justify;

  .support-link {
    color: #57c6ff;
    text-decoration: underline !important;
  }
}
</style>